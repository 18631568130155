import OnScreen from 'onscreen';
import imagesLoaded from 'imagesloaded';
// import topbar from 'topbar';
import ProgressBar from 'progressbar.js';



var line = new ProgressBar.Line('#progress', {
    color: '#1a1a1a',
    duration: 3000,
    easing: 'easeInOut',
    strokeWidth: .1,
    autoStyleContainer: true,
});

// Animate the progress bar to completion and hide it afterward
line.animate(1, {}, function () {
    // Hides the progress bar after completion
    document.querySelector('#progress').style.opacity = '0';
});

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// Include Lightbox 
import PhotoSwipeLightbox from './photoswipe-lightbox.esm.js'
// register Swiper custom elements
register();


// Ladebalken starten
// topbar.show();


// import Modernizr from './modernizr.custom.min';
import Isotope from "isotope-layout";

// if (!Modernizr.touch) {
// console.log('Please enable touch');
var os = new OnScreen({
    tolerance: 0,
    debounce: 100,
    container: window,
    toggleClass: 'imHere'
});

os.on('enter', '.scrollimation', (element, event) => {
    element.classList.add('onScreen');
    // console.log(event);
    // jQuery(this).addClass('onScreen');
});
// }




document.addEventListener('DOMContentLoaded', function () {

    function handleScroll() {
        var html = document.documentElement;
        var menu = document.querySelector('.menu');
        var scrollPosition = window.innerHeight + window.scrollY;
        var documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition >= documentHeight) {
            html.classList.add('menu-open');
            menu.classList.add('is-open');
        } else {
            html.classList.remove('menu-open');
            menu.classList.remove('is-open');
        }
    }

    function updateEventListener() {
        if (window.innerWidth >= 768) {
            window.addEventListener('scroll', handleScroll);
        } else {
            window.removeEventListener('scroll', handleScroll);
        }
    }

    // Initial check
    updateEventListener();

    // Listen for window resize
    window.addEventListener('resize', updateEventListener);



    // swiper element
    // Get the initial swiper container element
    const initSwiper = document.querySelector('swiper-container');


    // Set the parameters for the swiper
    const swiperParams = {
        autoplay: {
            delay: 5000 // Set the duration of each slide to 10 seconds (10000 milliseconds)
        },
        spaceBetween: 30,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        loop: true,
        speed: 2000,
        navigation: false,
        pagination: {
            clickable: true,
        },
    };

    if (initSwiper) {


        // Assign the parameters to the swiper element
        Object.assign(initSwiper, swiperParams);

        // Initialize the swiper
        initSwiper.initialize();

        // Get the swiper element
        const swiper = document.querySelector('.swiper').swiper;

        // Set the slide captions on load
        var sizes1 = document.querySelector('.swiper-slide-active').getAttribute('data-title');
        document.querySelector('.slide-captions').innerHTML = "<h2 class='current-title  text-xs active'>" + sizes1 + "</h2>";

        // Update the slide captions on slide change
        swiper.on('slideChangeTransitionStart', function () {
            // console.log('slideChangeTransitionStart');
            // Get the title of the current slide
            var slideTitle = document.querySelector('.swiper-slide-active').getAttribute('data-title');

            // Update the slide captions with the current slide title
            document.querySelector('.slide-captions').innerHTML = "<h2 class='current-title text-xs '>" + slideTitle + "</h2>";

            // Add the "active" class to the current slide title and remove it from others
            var currentTitle = document.querySelector('.current-title');
            currentTitle.classList.remove('active');
            currentTitle.classList.add('active');
        });


    }


    // Isotope

    var isotopeItem = document.querySelector('.projects .projects-container');
    // console.log(isotopeItem);

    if (isotopeItem) {
        imagesLoaded(isotopeItem, function () {
            console.log('images loaded');
            var iso = new Isotope('.projects-container', {
                itemSelector: '.grid-item',
                layoutMode: "fitRows",
                getSortData: {
                    order: '[data-order] parseInt' // Parse `data-order` as integer for sorting
                }
            });

            var isShowingInProgress = false;

            // bind filter button click
            var filtersElem = document.querySelector('.filters-button-group');

            filtersElem.addEventListener('click', function (event) {
                // only work with buttons
                if (!event.target.matches('button')) {
                    return;
                }

                var filterValue = event.target.getAttribute('data-filter');
                var sortByValue = event.target.getAttribute('data-sort-by');

                // Check if "inprogress" button was clicked
                if (filterValue === '.inprogress') {
                    isShowingInProgress = !isShowingInProgress; // Toggle visibility
                    const inProgressItems = document.querySelectorAll('.grid-item.inprogress');
                    inProgressItems.forEach(item => {
                        if (isShowingInProgress) {
                            item.classList.remove('hidden');
                        } else {
                            item.classList.add('hidden');
                        }
                    });
                } else {
                    // Reset "inprogress" items when other filters are applied
                    const inProgressItems = document.querySelectorAll('.grid-item.inprogress');
                    inProgressItems.forEach(item => item.classList.add('hidden'));
                    isShowingInProgress = false;
                }

                // Apply Isotope filtering
                iso.arrange({
                    filter: filterValue,
                    sortBy: sortByValue || 'order' // Default to sorting by `order`
                });
            });

            // change is-checked class on buttons
            var buttonGroups = document.querySelectorAll('.button-group');
            for (var i = 0, len = buttonGroups.length; i < len; i++) {
                var buttonGroup = buttonGroups[i];
                radioButtonGroup(buttonGroup);
            }

            function radioButtonGroup(buttonGroup) {
                buttonGroup.addEventListener('click', function (event) {
                    // only work with buttons
                    if (!event.target.matches('button')) {
                        return;
                    }
                    buttonGroup.querySelector('.is-checked').classList.remove('is-checked');
                    event.target.classList.add('is-checked');
                });
            }
        });
    }





    const moreInfo = document.querySelector('.project .more-info')
    document.querySelector('.nav-toggle').addEventListener('click', function (e) {

        e.preventDefault();
        toggleMenu();

    });



    /**
     * Toggles the menu by adding or removing necessary classes from elements.
     */
    function toggleMenu() {
        let main_navigation = document.querySelector('.menu'); // Selects the main navigation element with class 'menu'
        let html = document.querySelector('html'); // Selects the HTML element
        var body = document.querySelector('body');

        body.classList.toggle('overflow-body-hidden');
        main_navigation.classList.toggle('is-open'); // Toggles the 'is-open' class on the main navigation element
        html.classList.toggle('overflow-y-hidden'); // Toggles the 'overflow-y-hidden' class on the HTML element
        html.classList.toggle('menu-open'); // Toggles the 'menu-open' class on the HTML element
    }


    function projectInfo() {
        let shortInfo = document.querySelector('.short-info');
        let projectText = document.querySelector('.project-text');
        let moreInformation = document.querySelector('.more-information');
        let lessInformation = document.querySelector('.less-information');
        let figCaaption = document.querySelector('.project article figcaption');

        // let header = document.querySelector('header.header');
        // let primaryMenu = document.querySelector('.menu');

        figCaaption.classList.toggle('is-open');
        moreInformation.classList.toggle('hidden');
        lessInformation.classList.toggle('hidden');
        projectText.classList.toggle('hidden');
        shortInfo.classList.toggle('hidden');

    }


    if (moreInfo) {
        moreInfo.addEventListener('click', function (e) {

            e.preventDefault();
            projectInfo();

        });
    }

    // function handleScreenResize() {

    //     if (screenWidth < 781) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    window.addEventListener('scroll', function () {
        var html = document.querySelector('html');
        var scrollPosition = window.scrollY || document.documentElement.scrollTop;

        if (scrollPosition >= 155) {
            html.classList.add('sticky');
            html.classList.remove('top');
        } else {
            html.classList.add('top');
            html.classList.remove('sticky');
        }
    });


    const container = document.querySelector('.container-width');
    console.log(container);

    // // Create a ResizeObserver to watch for changes in element width
    // const resizeObserver = new ResizeObserver(entries => {
    //     for (let entry of entries) {
    //         const width = entry.contentRect.width;

    //         // Update the CSS variable with the current width of the element
    //         document.documentElement.style.setProperty('--container-width', `${width}px`);
    //     }
    // });

    // // Observe the container element
    // resizeObserver.observe(container);



});


//cookies - Reload page on SAVE
document.querySelector('body').addEventListener('cookies:saved', ({ detail }) => {
    // console.log('Saved cookie features:', detail);

    location.reload();
})





// $(document).on('click', '[data-toggle="kkg-lightbox"]', function (event) {
// alert('Fire');
// event.preventDefault();

// lightbox.init();
// });



document.querySelectorAll('.open-lightbox').forEach(function (item) {

    item.addEventListener('click', function (event) {
        event.preventDefault();
        openLightbox(this.getAttribute('data-slug'));
    });
});


function openLightbox(slug) {
    // console.log(slug);
    const lightbox = new PhotoSwipeLightbox({
        gallery: '#news-gallery-' + slug,
        children: 'a:not(a.button)',
        showHideAnimationType: 'none',
        zoomAnimationDuration: false,
        zoomEl: false,
        mainClass: 'pswp--custom-bg',
        showHideAnimationType: 'none',
        zoomAnimationDuration: false,
        zoomEl: false,
        showAnimationDuration: 700,
        hideAnimationDuration: 700,
        // arrowPrevSVG: leftArrowSVGString,
        // arrowNextSVG: rightArrowSVGString,
        counter: false,
        zoom: false,
        bgOpacity: 1,
        imageClickAction: 'next',
        tapAction: 'next',
        pswpModule: () => import('./photoswipe.esm.js'),

        paddingFn: (viewportSize) => {
            return {
                top: viewportSize.x < 1000 ? 50 : 100,
                bottom: viewportSize.x < 1000 ? 50 : 100,
                left: viewportSize.x < 1000 ? 50 : 100,
                right: viewportSize.x < 1000 ? 50 : 100,
            }
        }
    });
    // console.log(lightbox);

    // lightbox.init();

    lightbox.loadAndOpen(0, {
        gallery: document.querySelector('#news-gallery-' + slug)
    });

}


